import React from "react"
import styled from "styled-components"

import { Section, SectionTitle, StyledContainer } from "../../global"

const KnjigaDescription = () => (
  <Section>
    <StyledContainer>
      <SectionTitle>Opis</SectionTitle>
      <SectionDescription>
        <p>
          Na Zahodu se nahajamo v obdobju, v katerem prevladujeta dva kulta, in sicer kult telesa in zdravja. Telovadnice, welness in fitness centri so prepolni, vse več ljudi kolesari, teče in telovadi na prostem. Vse to počnejo z namenom, da bi se bolje počutili, da bi skurili vsakdanji stres, da bi bili v družbi, zaradi bolj izklesane postave, sprejemljivejši in privlačnejši. V rumenem tisku, časopisih, na internetu, v lekarnah in najrazličnejših drogerijah, lahko najdemo vse mogoče pripomočke in nasvete za nego telesa, za izboljšanje zdravja in počutja. Smemo reči, da ves Zahod stremi k idealnemu telesu, lepoti in zdravju. Zdravje je nedvomno velika vrednota. Vsak človek si želi biti zdrav. Ko si Slovenci voščimo ob rojstnih dnevih ali novem letu, si navadno voščimo in zaželimo predvsem ›zdravja‹. Kljub temu, da
          v družbi vse bolj narašča težnja k doseganju duševnega zdravja, v zadnjem času tudi duhovnega, pa se mnogi žal še vedno ukvarjajo le z zdravljenjem in negovanjem telesa. Skrb za telo in fizična kondicija sta pomembna za človeka in potrebno je, da ju ovrednotimo, vendar nista najpomembnejša, ko gre za ohranjanje oziroma doseganje človekovega zdravja.
          Vse večji porast je t. i. holistične ali alternativne (new age) medicine, znotraj katere je nešteto terapij, tehnik in metod zdravljenja. Te človeka obravnavajo celostno in mu pri tem, na najrazličnejše načine, nudijo celostno, holistično zdravje. Tovrstna ›medicina‹, nekateri ji pravijo ›energetska‹, zahodnjakom prinaša nov pogled na človeka
          (novo antropologijo), ki je tuj tako krščanstvu kot znanosti. Zanjo je bistveno, da ponuja ›zdravljenje z energijami‹: ki, či, prana, bioenergija, življenjska sila itd. Zahodnjakom prinaša ne le nov pogled na človeka, marveč tudi na Boga, svet in življenje. Medicina se ukvarja s telesom in dušo, je religiozno nevtralna, medtem ko alternativna oziroma ›holistična medicina‹ želi zdraviti še človekovega duha.
        </p>
        <p>
          Zahod preplavljajo še tehnike in meditacije, ki prihajajo iz Daljnega Vzhoda (Indije, Kitajske in Japonske), in že nekaj desetletij (vedno bolj) osvajajo Zahod. Tudi te ponujajo celostno zdravje, boljše počutje in kvalitetnejše življenje. Zahodni človek pogosto ob ›paleti ponudb‹ niti ne vé, kam naj se obrne, česa naj se oprime, kaj je zanj resnično dobro in kaj laž/prevara, kje naj išče zdravje, srečo, izpolnjenost in mir.
        </p>
        <p>
          Kljub množici informacij in ponudb za doseganje zdravja, mnogi žal ne vedo, da je medčloveški odnos izjemno pomemben, če že ne najpomembnejši, za človekovo dobro počutje, doseganje in ohranjanje zdravja ter miru v duši. Temu dodajmo, da ne le odnos s sočlovekom, marveč tudi človekov odnos do samega sebe in svojega Stvarnika. Vsi ti odnosi močno vplivajo na posameznikovo dobro počutje, celostno zdravje in mir v duši. Iz izkušenj vemo, kakšno je stanje v naši duši, ko smo v miru s samim seboj in drugimi, ko v odnosih z bližnjimi ni trenj in napetosti, ko v našem srcu ni zamer, jeze, besa, obsojanja, obtoževanja, očitkov, pohujšanj, sovraštva, maščevalnosti, prevar, laži, goljufij itd. Takrat se navadno počutimo dobro, doživljamo mir, tako v notranjosti kot tudi v glavi (mislih). Ko pa smo z nekom v konfliktu, ko se s kom ne razumemo, ko smo prizadeti, pa izkušamo, kako oseba, ki nas je prizadela, zaposluje naše misli in vznemirja ter prebuja negativna notranja občutja. Običajno veliko razmišljamo o njej, posebej ko smo sami in v tišini, saj se nam negativne, hudobne misli v zvezi z njo ves čas vsiljujejo, vrinjajo v ›glavo‹, ob
          tem pa še prebujajo ›negativna‹ čustva v obliki jeze, besa, sovraštva ..., pa tudi negativna nagnjenja k obsojanju, obtožbam, očitkom, moraliziranju itd. Ta čutenja in misli nas dobesedno trpinčijo in mučijo dokler se ne odločimo za odpuščanje, ki se začne v volji, želji po njem, v odločitvi, in vodi k ozdravljenju spomina, sprostitvi negativnih čustev in ozdravljenju čustvenih ran. Samo krotko in ponižno srce lahko pride do resničnega miru v duši. To pravi Jezus
          sam (Mt 11,29).
        </p>
        <p>
          Pogosto se sami ›borimo‹, da bi prišli do miru v duši, a žal na napačen način, zato nemir ostaja. Nemir v duši nam povzročajo tudi naša dejanja in besede, potem ko nekoga prizadenemo, saj začutimo, če le imamo kolikor toliko oblikovano vest, da se mir v naši duši izgublja, nemir oziroma napetost pa naraščata. Ko nekoga prizadenemo, ne prizadenemo le njega, marveč tudi sebe. Rani sta torej dve in kdo ju more resnično zaceliti? Dobro in zaželeno je, da se iskreno opravičimo. Če nam prizadeti odpusti, nam je lažje pri srcu. Naše iskreno kesanje more sprožiti zdravljenje ran v naši in njegovi duši. Vendar kljub vsemu prizadevanju pogosto doživljamo in opažamo, da nemir in bolečina ostajata v obeh dušah. Torej potrebujemo nekaj več. Potrebujemo spravo in to celostno. S samim seboj, z bližnjim in ne nazadnje z Njim, ki nas je ustvaril. Samo to – celostna sprava – človeku prinaša resnični notranji mir! Nismo samo duševna in telesna bitja, marveč tudi duhovna. Odpuščanje od ›zgoraj‹, od navpične smeri, more pomiriti, očistiti madeže in ozdraviti rane v moji duši. Kje ga lahko dobimo? Kdo nam lahko odpusti?
          Danes se mnogi kristjani, nekristjani in katoličani, ki so se oddaljili in opustili ›prakso‹ spovedovanja grehov, sprašujejo, kakšen smisel ima iti k duhovniku katoliške Cerkve in mu naštevati svoje spodrsljaje, grehe? Ob tem se opravičujejo in trdijo, da niso nikoli storili česa tako slabega, npr. nekoga umorili, kaj ukradli, naredili splav, prevarali partnerja, koga pretepli itd., zaradi česar bi morali prositi Boga odpuščanja. Menijo, da se je treba pokesati le za ›velike‹ grehe. Cerkev na Slovenskem glede tega ugotavlja: »Danes se odnos do greha in lastne grešnosti spreminja, s tem pa se spreminja tudi odnos do zakramenta sprave. Veliko je ljudi, ki greha ne razumejo kot enega bistvenih vzrokov za svoje rane, razočaranja, trpljenje, zato zakramenta pokore in sprave ne doživljajo kot osvobajajoče in zdravilno sredstvo. Nauk Cerkve o zakramentu sprave jim je tuj, zato le redko pridejo k spovedi ali pa sploh ne pridejo. Prav to pa ustvarja med verniki prepričanje, ki zagovarja predvsem subjektivna merila o tem, kaj je greh in kaj ni.«
        </p>
        <p>
          Po drugi strani pa ti isti ne zanikajo, da so se v življenju kdaj zlagali, se vedli sebično, mislili najprej in samo nase, opravljali svoje bližnje, nadrejene, ali o njih celo klevetali, jih obrekovali – o njih grdo in lažno govorili. Ne zanikajo, da so kdaj koga ponižali, osramotili, naredili komu krivico, krivično obsojali, sodili, pretirano in nekonstruktivno kritizirali, o kom (namerno) slabo mislili, bili komu nevoščljivi glede ljubezni, dobrin itd., bili na koga ljubosumni, hinavski, dvolični. Ne zanikajo, da so kdaj gledali na nasprotni spol poželjivo in ga s tem popredmetili, da so se nekaterim na različne načine maščevali, da so bili kdaj na koga silovito jezni in mu niso želeli odpustiti. Ne zanikajo, da so koga sovražili, pretiravali z uživanjem alkohola in kajenjem, da so jemali drogo in s tem ogrožali svoje zdravje oziroma življenje. Na sebi ne zanikajo nekaterih vedenjskih ›motenj‹ kot so: domišljavost, aroganca oziroma
          napuh, vzvišenost, norčevanje, posmehovanje, podcenjevanje ... Ti so kot strupi, ki poleg negativnih čustev, razočaranj, samopomilovanja, obsodb in obtožb, zastrupljajo njih same ter ljudi okoli njih. Za tovrstne strupe, ki izhajajo iz srca, ne obstajajo tablete,zdravila, ki bi jih mogel iznajti človek in jih ozdraviti. Samo Bog, ki se je razodel v Jezusu Kristusu, more očistiti, ozdraviti in osvoboditi človekovo srce vseh duhovnih ›strupov‹, ›virusov‹, ›okužb‹ ter ga preobraziti. O vsem tem se mnogi žal ne sprašujejo, nekatere izmed zgoraj naštetih ›negativnosti‹ sprejemajo in imajo za ›normalne‹. Opravičujejo in tolažijo se s frazo: »Vsi tako počnejo«. Na ta način lažno pomirjajo svojo vest. Toda te stvari ostajajo v človeškem telesu in ga od znotraj zastrupljajo. Posledice notranjega ›zastrupljanja‹ se žal prej ali slej pokažejo tudi na duševni in telesni ravni, v obliki psihosomatskih obolenj oziroma bolezni.
          Mnogi, ki so prenehali hoditi k spovedi, pa svojo odločitev opravičujejo takole: »Zakaj bi še vedno hodil k spovedi, ko pa potem ni nič boljše? Kljub neštetim spovedim še vedno ponavljam enake grehe in ne vidim smisla, da bi se jih še naprej spovedoval!« Namesto pritoževanja bi bilo veliko bolje in koristneje, da bi bili Bogu hvaležni, da jih varuje pred novimi grehi. Si mar kdo želi pasti v nove grehe?
        </p>
        <p>
          Nekateri zagovarjajo mnenje, da se lahko Bogu (iz)spovemo neposredno. Pravijo, da človek ne potrebuje posrednika: »Zakaj bi moral svoje spodrsljaje, grehe pripovedovati nekemu človeku?«, se sprašujejo mnogi. »Saj je Bog povsod navzoč in vse vé, zato ni potrebno, da bi svoje grehe (slabosti) razkrival človeku. Bog me sliši, ni gluh in če je odpuščajoči Bog, mi bo odpustil, ko ga bom iskreno prosil odpuščanja«, odgovarjajo prepričljivo. »Če mi bo zares žal in če ga bom iskreno prosil odpuščanja, mi bo morda odpustil«, menijo tisti, ki dvomijo v Božje odpuščanje in usmiljenje. Tisti, ki vanj zaupajo, pa v tem primeru prepričljivo vzkliknejo: »Mi bo odpustil!« A pri tem ne eni ne drugi nimajo zagotovila. Nekateri, ki jih krivda za njihove spodrsljaje močno razjeda, pa rečejo: »Ne verjamem, da mi Bog lahko to odpusti«, oziroma: »Bog mi tega ne more nikoli odpustiti! Moje dejanje presega vsakršno odpuščanje
          in usmiljenje! Ne zaslužim si ga!« Tako nekako je v svojem srcu čutil Kajn, potem ko je ubil Abela. Po tem dejanju je rekel: Moja krivda je prevelika, da bi jo mogel nositi (1 Mz 4,13). Podobno je najbrž čutil tudi hromi mladenič,
          ki so ga na nosilih prinesli pred Jezusa, z željo, da bi ga ozdravil. Ko ga je Jezus zagledal, mu je rekel: Bodi pogumen (zaupaj), otrok, odpuščeni so ti grehi! (Mt 9,2). Krivda, ki nas razžira, ko naredimo nekaj slabega, je zares breme, ki ga včasih ni mogoče nositi. Je kot tovor, položen v našo dušo, ki postaja iz dneva v dan neznosnejši. Vpliva na naše mišljenje, čutenja in obnašanje. Kdo nam lahko odvzame to krivdo, ta tovor? Kdo nas je lahko osvobodi? Kdo jo je odvzel Kajnu, da je lahko preživel?
        </p>
        <p>
          Knjiga, ki jo držite v rokah, je sestavljena iz štirih delov. V prvem delu govori o Bogu, njegovi pravi podobi in njegovi odpuščajoči ljubezni do človeka. Ob svetopisemskih odlomkih bomo spoznavali, kdo pravzaprav je Bog, ki se je človeštvu (dokončno in v polnosti) razodel v Jezusu Kristusu; nadalje, kdo je človek za Boga, kakšen odnos ima Bog do njega in kakšen je Božji pogled na človeka? Spoznavali bomo tudi človeka, njegovo naravo, ki se najrazličnejših bremen lahko (od)reši sama ali vendarle potrebuje pomoč, odrešenje od ›zunaj‹? V drugem delu bomo spregovorili o tem, kakšen odnos naj ima od Boga ljubljeni človek do svojega Stvarnika; kako naj pristopa k Njemu, da bi bil polneje deležen vseh milosti, ki mu jih ponuja v/po svojem Sinu. V tretjem delu se bomo lotili Božjih zapovedi, ki so človeštvu
          dane kot življenjska navodila, ›recepti‹ za dobro in kvalitetno življenje, kot prometni znaki in smerokazi za hojo po pravi poti. Vsako izmed njih bomo podrobneje obravnavali in jo poskušali čim bolj aktualizirati. Pri mnogih kristjanih je poznavanje desetih zapovedi izjemno šibko in površno. Prenekateri si sami ustvarjajo merila kaj je za njih dobro in kaj slabo, pogosto po merilih ›splošnega mnenja‹ okolice ali širše družbe, pozabljajo pa, da je njihovo merilo, zgled in ideal Jezus Kristus. Četrti del sestavljajo vprašalnik (za spraševanje vesti) in dodatka (slovar pojmov in obred spovedi). Poudariti želimo pomembnost branja opomb, saj so na mnogih mestih zelo pomembne in bralcu pomagajo k boljšemu razumevanju besedila, nekatere pa z navajanjem literature omogočajo bralcu možnost poglabljanja določene tematike. Skozi poglavja bo bralec lahko odkrival lepoto in koristnost ›spovednice‹, ki je na ›tržnici‹ ponudb zdravja, lepote in miru, najučinkovitejše ›zdravilišče‹ in ›lepotni salon‹. Radi bi pojasnili tudi stil našega pisanja. Besede, ki smo jih želeli poudariti, smo označevali na tri načine: najpomembnejše s krepko pisavo, pomembne pa s poševno/ležečo pisavo oziroma v enojnem srednjem narekovaju.
        </p>
        <p>
          Knjiga ni namenjena le kristjanom in katoličanom, temveč vsem iskalcem duhovnega in duševnega zdravja, notranjega miru, Božjega odpuščanja, smisla in polnosti življenja, vsem ljudem dobre volje, ki bi želeli globlje spoznati sporočilo Božje odpuščajoče ljubezni, ki človeka spreminja, preobražuje, ozdravlja in počlovečuje. Sprava z Bogom človeku omogoča dostop do polnega, srečnega življenja, po katerem hrepeni. Brez tega ni resnične sreče. Vernim katoličanom in duhovnikom more služiti tudi kot priročnik oziroma priprava na srečanje z usmiljenim Očetom v zakramentu sprave.
        </p>
        <p>Besedilo je vzeto iz Predgovora avtorja</p>
      </SectionDescription>
    </StyledContainer>
  </Section>
)

export default KnjigaDescription

const SectionDescription = styled.div`

`