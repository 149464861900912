import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { FaShoppingCart, FaDownload } from "react-icons/fa"
import Kazalo from "../../../../files/kazalo.pdf"

import {
  Container,
  StyledLink,
  StyledHeaderImage,
  ImageWrapper,
  Flex,
  ActionButton,
  HeaderTextGroup,
  Subtitle,
  HeaderWrapper,
} from "../../global"

const KnjigaHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "knjiga" }
        name: { eq: "knjiga-naslovnica" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Knjiga</Subtitle>
            <h1>
              Božja ljubezen
              <br />
              odpušča in ozdravlja
            </h1>
            <h5>
              <Italic>Avtor:</Italic> <Bold>Boštjan Hari</Bold>
            </h5>
            <h5>
              <Italic>Strokovni pregled:</Italic>{" "}
              <Bold>dr. Marjan Turnšek in mag. Vlado Bizjak</Bold>
            </h5>
            <h5>
              <Italic>Spremna beseda:</Italic> <Bold>mag. Vlado Bizjak</Bold>
            </h5>
            <h5>
              <Italic>Mnenje o knjigi:</Italic>{" "}
              <Bold>dr. Maksimilijan Matjaž in dr. Andrej Šegula</Bold>
            </h5>
            <KazaloLink href={Kazalo} download>
              <KazaloActionButton>
                <FaDownload /> Kazalo
              </KazaloActionButton>
            </KazaloLink>
            <StyledLink to="/nakup-knjige/">
              <CartButton>
                <FaShoppingCart /> Cena: 22,95 eur
              </CartButton>
            </StyledLink>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledHeaderImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default KnjigaHeader

const KazaloActionButton = styled(ActionButton)`
  margin-bottom: 1rem;
`

const KazaloLink = styled.a`
  text-decoration: none;
`

const Italic = styled.span`
  font-style: italic;
`
const Bold = styled.span`
  font-family: ${(props) => props.theme.font.bold};
`
const CartButton = styled(ActionButton)`
  background: ${(props) => props.theme.color.accent};
`
