import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import KnjigaHeader from "../components/sections/knjiga/knjigaheader"

import Footer from "../components/common/footer"
import KnjigaDescription from "../components/sections/knjiga/knjigadescription"

const Knjiga = () => (
  <Layout>
    <SEO title="O knjigi" />
    <KnjigaHeader />
    <Navigation />
    <KnjigaDescription />
    <Footer />
  </Layout>
)

export default Knjiga
